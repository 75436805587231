import React from "react"
import styled from "styled-components"

import Layout from "../theme/layout"
import SEO from "../theme/header/seo"

import { getProperty } from "../../lib/utils"

const BasicContentPage = ({ pageContent, pathContext, location }) => {
  const title = getProperty(pageContent, ["seaPage", "seo", "title"], "")
  const yoast = getProperty(pageContent, ["seaPage", "seo"], {})
  const content = getProperty(pageContent, ["wpPage", "content"], "")

  return (
    <Layout pathContext={pathContext} location={location}>
      <SEO title={title} location={location} yoast={yoast} />
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <BasicContentPageContent dangerouslySetInnerHTML={{ __html: content }} />
    </Layout>
  )
}

export default BasicContentPage

// ===============
//     STYLES
// ===============
const BasicContentPageContent = styled.div`
  h2 {
    margin-top: 3rem;
  }
  h3 {
    margin-top: 3rem;
    font-weight: 600;
  }
`
